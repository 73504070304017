<template>
  <v-app class="grey lighten-3">
    <app-drawer :drawer.sync="drawer"></app-drawer>

    <v-toolbar app :clipped-left="clipped" color="primary" dark>
      <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
      <v-toolbar-title v-text="tituloTela"></v-toolbar-title>
      <v-progress-circular v-show="loading" indeterminate :size="25" :width="3" color="primary" class="ml-4"></v-progress-circular>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-content>
      <v-slide-y-transition mode='out-in'>
        <router-view/>
      </v-slide-y-transition>
    </v-content>

    <v-snackbar bottom v-model="snackbar.exibir" :timeout="3000">
      {{ snackbar.mensagem }}
      <v-btn flat color="primary" @click.native="snackbar.exibir = false">Fechar</v-btn>
    </v-snackbar>

    <Notifications ref="notifications"></Notifications>

    <z-debug-info v-show="!$config.IS_PRODUCTION"></z-debug-info>

  </v-app>
</template>

<script>
// import MenuPrincipal from "@/pages/layout/MenuPrincipal";
import { mapState } from 'vuex';

import AppDrawer from "./AppDrawer";
import ListasService from "@/services/listas.js"
import createLocalStorage from "@/utils/localstore.js"
import Notifications from '@/pages/Notifications'
import zDebugInfo from "@/components/zDebugInfo";

const localstore = createLocalStorage({
  STORAGE_ENCRYPT: false,
  APP_PREFIX: 'COB'
});


export default {
  name: "AppLayout",

  components: {
    // MenuPrincipal,
    AppDrawer,
    Notifications,
    zDebugInfo,
  },

  provide() {
    return {
      mensagem: 'teste',
      rootRefs: this.$refs
    }
  },

  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Tela Inicial",
      snackbar: {
        exibir: false,
        mensagem: ''
      },
    };
  },

  // created() {
  //   this.$eventbus.onNotificar((mensagem) => {
  //     this.snackbar.exibir = true;
  //     this.snackbar.mensagem = mensagem;
  //   });
  // },

  // beforeDestroy(){
  //   this.$eventbus.$off('EXIBIR_MENSAGEM');
  // },

  computed: {
    ...mapState({
      tituloTela: state => state.tela,
      loading: state => state.loading,
    }),
  },


  mounted() {
    this.carregarEstados();
  },

  methods: {
    async alertar() {
      console.log(this.$refs.notifications.alertar)

      this.$refs.notifications.notificarErro("teste")

      let resposta = await this.$refs.notifications.dialogo_pergunta.abrir({
        titulo: 'Remover Banco?',
        texto: 'O banco será removido do sistema.',
        botoes: ['Cancelar', 'Remover'],
        padrao: 'Remover',
        cor: 'red',
      });

      this.$refs.notifications.dialogo_pergunta.fechar();
    },

    async carregarEstados() {

      console.log("Carregar estados");
      const dia = 1;
      const estados = await localstore.remember('estados', dia * 1, function() {
        console.log("Rodou callback cache");

        const estados_teste = ListasService.estados();
        return estados_teste;

      });

      this.$store.commit('SET_ESTADOS', estados);

      // localstore.cache_set('estados', 1, {
      //     "testes": 123
      //   }
      // );

      // console.log('estados_cache', localstore.cache_get('estados'));


      // const estados = await ListasService.estados();

      // console.log(estados);
    }
  },

};
</script>
