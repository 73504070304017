<template>

    <v-form ref="form" v-model="valido" lazy-validation>

        <v-dialog v-model="dialog" persistent max-width="600">
            <v-card>

                <v-card-title primary-title>
                    <div class="title">Enviar Arquivo</div>
                </v-card-title>
                <v-divider></v-divider>
<!--
                <v-container grid-list-md fluid>
                  <v-layout row>
                      <v-flex sm5>
                          <v-select
                            v-model="arquivo.dia_vencimento"
                            label="Dia Vencimento"
                            :items="vencimentos"
                            :rules="[rules.campoObrigatorio]"
                            @change="onChangeVencimento"
                          ></v-select>
                      </v-flex>
                      <v-flex sm5>
                        <v-select
                          :items="meses"
                          label="Mês de Referência"
                          v-model="arquivo.mes"
                          @change="onChangeVencimento"
                        ></v-select>
                      </v-flex>
                      <v-flex sm4>

                        <v-text-field
                          v-model="arquivo.ano"
                          ref="ano"
                          name="ano"
                          label="Ano"
                          readonly
                        ></v-text-field>

                        <v-select
                          :items="anos"
                          label="Ano"
                          v-model="arquivo.ano"
                          @change="onChangeVencimento"
                        ></v-select>
                      </v-flex>
                  </v-layout>

                  <v-layout row>
                      <v-flex sm12>
                          <v-text-field
                            ref="descricao"
                            label="Descrição Arquivo"
                            v-model="arquivo.descricao"
                            :rules="[rules.tamanhoMinimo]"
                            hide-details
                            autofocus required
                            readonly
                          ></v-text-field>
                      </v-flex>
                  </v-layout>

                </v-container> -->

                <v-container>
                    <input type="file" ref="fileinput" style="display: none" @change="onChangeFile">
                    <v-layout v-if="!arquivo.nome" class="dropzone" justify-center align-center @click="escolherArquivo">
                        <span>Clique aqui para adicionar um arquivo</span>
                    </v-layout>
                    <v-layout v-else row wrap class="dropzone-uploading" align-center>
                        <v-flex xs12>
                            <span>{{ arquivo.nome }}</span>
                        </v-flex>
                        <v-flex xs12>
                            <v-progress-linear v-model="progress" height="5" color="primary"></v-progress-linear>
                        </v-flex>
                    </v-layout>
                </v-container>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey" flat :disabled="loading" @click="fechar">Cancelar</v-btn>
                    <v-btn @click.native="upload" flat color="indigo darken-1" class="white--text" :disabled="loading || !this.selectedFile || !valido">Enviar</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </v-form>
</template>

<script>

  import axios from 'axios'

  export default {
    data() {
      return {
          tela: 'Arquivos',
          selectedFile: null,
          progress: 0,
          loading: false,
          dialog: false,
          arquivo: {
            nome: null,
            descricao: null,
            tipoarquivo: null,
            mes: null,
            ano: null,
            dia_vencimento: null
          },
          tiposligacoes: [
              {text: 'Detalhado', value: 'detalhado'},
              {text: 'Natureza Ligações', value: 'natureza'}
          ],
          valido: true,
          rules: {
            campoObrigatorio(v) {
              return !!v || 'Campo obrigatório';
            },

            tamanhoMinimo(v) {
              return !!v && v.length >= 5 || 'Informe uma descrição para esse arquivo'
            }
          },
          meses: [
            {text: 'Janeiro', value: 1},
            {text: 'Fevereiro', value: 2},
            {text: 'Março', value: 3},
            {text: 'Abril', value: 4},
            {text: 'Maio', value: 5},
            {text: 'Junho', value: 6},
            {text: 'Julho', value: 7},
            {text: 'Agosto', value: 8},
            {text: 'Setembro', value: 9},
            {text: 'Outubro', value: 10},
            {text: 'Novembro', value: 11},
            {text: 'Dezembro', value: 12}
          ],
          anos: []
      }
    },

    computed: {
      vencimentos() {
        return this.$store.state.listas.vencimentos;
      }
    },

    mounted() {

    },

    methods: {
        gerarAnos() {
          let inicio    = 2018;
          let fim       = new Date().getFullYear();
          let diferenca = fim - inicio;
          let anos      = [];

          if (diferenca <= 5) {
            diferenca = 5;
          }

          for (let index = 0; index <= diferenca; index++) {
            anos.push(inicio + index);
          }

          return anos;
        },

        async abrir() {
          await this.$store.dispatch('listas/listarVencimentos');
          this.anos = this.gerarAnos();

          let currentMonth = new Date().getMonth() + 1;
          let currentYear = new Date().getFullYear();

          if (this.arquivo.mes == 1) {
            currentMonth = "12";
            currentYear -= 1;
          }

          this.arquivo.mes = this.meses[currentMonth - 1].value;
          this.arquivo.ano = currentYear;
          this.arquivo.dia_vencimento = this.vencimentos[0];
          this.arquivo.descricao = (this.meses[currentMonth - 1].text).toUpperCase() + ' ' + this.hoje().split('-').reverse().join("/");

          this.dialog = true;
          this.onChangeVencimento();
        },

        fechar() {
            this.dialog                 = false;
            this.selectedFile           = null;
            this.arquivo.nome           = null;
            this.arquivo.descricao      = null;
            this.arquivo.tipo           = null;
            this.arquivo.dia_vencimento = null;
            // this.rules               = [];
            this.$refs.fileinput.value  = null;

            setTimeout(() => {
              this.$refs.form.resetValidation();
            }, 10);

            this.$emit('cancelar');
        },

        onChangeFile(e) {
            this.arquivo.nome = e.target.files[0].name;
            this.selectedFile = e.target.files[0];
        },

        upload() {

            // this.arquivo.descricao =
            this.carregarRegras();
            this.$nextTick(() => {

                if (this.$refs.form.validate()) {

                    if (!this.selectedFile) {
                    return false;
                    }

                    this.loading  = true;
                    this.progress = 0;
                    const fd      = new FormData();
                    fd.append('arquivo', this.selectedFile);
                    fd.append('descricao', this.arquivo.descricao);
                    fd.append('mes', this.arquivo.mes);
                    fd.append('ano', this.arquivo.ano);
                    fd.append('tipoarquivo', this.arquivo.tipoarquivo);
                    fd.append('dia_vencimento', this.arquivo.dia_vencimento);

                    this.$axios.post('arquivos', fd, {
                        onUploadProgress: uploadEvent => {
                            this.progress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                        },
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        this.loading  = false;
                        this.progress = 0;
                        this.fechar();
                        this.$emit('onConcluir', response.data);
                    })
                    .catch(error => {
                        this.loading  = false;
                        this.progress = 0;
                        console.log(error);
                    });

                } else {
                    this.$refs.descricao.focus();
                }
            });
        },

        escolherArquivo() {
          this.$refs.fileinput.click();
        },

        carregarRegras() {
            // this.rules = [
            //     (v) => !!v && v.length >= 5 || 'Informe uma descrição para esse arquivo'
            // ]
        },

        uploadTimer() {
            this.progress = 0;
            var interval = setInterval(() => {
                this.progress = this.progress + 10;
                if (this.progress == 100) {
                    clearInterval(interval);
                }
                console.log(this.progress);
            }, 1000);
        },

        onChangeVencimento() {
          const mes = (this.meses[this.arquivo.mes - 1].text).toUpperCase().toUpperCase();
          const ano = this.arquivo.ano;
          const vencimento = this.arquivo.dia_vencimento;
          const hoje = this.hoje().split('-').reverse().join("/");

          this.arquivo.descricao = `${mes} ${hoje} (Vencimento: dia ${vencimento})`;

          // const vencimento_pos = this.arquivo.descricao.indexOf(" (Vencimento ");
          // if (vencimento_pos > -1) {
          //   const str_inical = this.arquivo.descricao.slice(0, vencimento_pos);
          //   this.arquivo.descricao = str_inical + " (Vencimento " + vencimento + ")";
          // } else {

          //   this.arquivo.descricao = this.arquivo.descricao + " (Vencimento " + vencimento + ")";
          // }
        }
    },

  }
</script>

<style>
    .dropzone {
        border: 2px dashed #BDBDBD;
        border-radius: 5px;
        background: #EEEEEE;
        padding: 25px 25px;
        cursor: pointer;
        /* min-height: 100px; */
    }

    .dropzone-uploading {
        border: 1px solid #BDBDBD;
        border-radius: 5px;
        background: #EEEEEE;
        padding: 15px 25px;
        /* min-height: 100px; */
    }
</style>
